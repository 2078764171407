import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
import Experience from "../src/Experience.js";
import $ from 'jquery'

export default class extends Controller {
  static values = { game: Boolean }

  connect() {
    this.experience = new Experience()
    this.mouse = this.experience.mouse
    this.video = document.getElementById("game-loading-video");
    this.cameraOrigin = this.mouse.cameraOrigin
    this.mouse.moveCameraTo(this.cameraOrigin)

    const gameLoadingModalElement = document.getElementById("game-loading-modal")
    this.gameLoadingModal = Modal.getOrCreateInstance(gameLoadingModalElement)

    gameLoadingModalElement.addEventListener('shown.bs.modal', () => {
      this.toggleMusicOff();
      this.video.currentTime = 0
      this.video.play()
    });

    this.video.addEventListener("ended", (event) => {
      this.video.setAttribute("videoPlayed", true)
    });

    document.querySelectorAll('#sound-toggle .sound-on').forEach((element) => {
      element.addEventListener('click', (event) => {
        this.toggleMusicOff()
      });
    });


    document.querySelectorAll('#sound-toggle .sound-off').forEach((element) => {
      element.addEventListener('click', (event) => {
        this.toggleMusicOn()
      });
    });

    document.addEventListener("DOMContentLoaded", function () {
      const largeBadge = document.querySelector(".outer-video-badge");
      const smallBadge = document.querySelector(".outer-video-badge-small");
      const minimizeButton = document.querySelector(".minimize-video");

      if (minimizeButton) {
        minimizeButton.addEventListener("click", function () {
          largeBadge.classList.add("hidden");

          setTimeout(() => {
            largeBadge.style.display = "none";
            smallBadge.style.display = "block";

            setTimeout(() => {
              smallBadge.classList.add("visible");
            }, 10);
          }, 500);
        });
      }

      if (smallBadge) {
        smallBadge.addEventListener("click", function () {
          smallBadge.classList.remove("visible");

          setTimeout(() => {
            smallBadge.style.display = "none";
            largeBadge.style.display = "block";

            setTimeout(() => {
              largeBadge.classList.remove("hidden");
            }, 10);
          }, 500);
        });
      }
    });
  }

  playVideo() {
    this.video.currentTime = 0
    this.video.play()
    this.toggleMusicOff();
  }

  dismissModal() {
    this.gameLoadingModal.hide()
    this.video.pause()
    this.toggleMusicOn()
    $('.navbar-nav .hidden').removeClass('hidden');
    $('.instructions.hidden').removeClass('hidden');
  }

  toggleMusicOff() {
    this.experience.world.backgroundMusic.stopMusic();
    $('#sound-toggle .sound-on').removeClass('active')
    $('#sound-toggle .sound-on').addClass('hide')
    $('#sound-toggle .sound-off').addClass('active')
    $('#sound-toggle .sound-off').removeClass('hide')
  }

  toggleMusicOn() {
    this.experience.world.backgroundMusic.playMusic();
    $('#sound-toggle .sound-off').removeClass('active')
    $('#sound-toggle .sound-off').addClass('hide')
    $('#sound-toggle .sound-on').addClass('active')
    $('#sound-toggle .sound-on').removeClass('hide')
  }
}
