import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hls-video"
export default class extends Controller {
  connect() {
    var video = this.element;
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(this.element.dataset.url);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        //video.play(); Keep for now 
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = this.element.dataset.url;
      //video.play(); Keep for now 
    }
  }
}
