import { Controller } from "@hotwired/stimulus"
import Experience from "../src/Experience.js"
import $ from 'jquery'
import * as _ from 'underscore'

export default class extends Controller {
  static targets = [
    'rainbtn',
    'explore-results-btn',
    'xbtn',
    'submit-leaderboard-btn'
  ];

  connect() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.camera = this.experience.camera
    this.mouse = this.experience.mouse
    this.world = this.experience.world
    this.resource = this.experience.resources
    this.parcels = this.experience.selectedParcels
    this.weatherAttempts = 0
  }

  makeRainClick(e) {
    this.mouse.moveCameraTo(this.mouse.makeRainOrigin)
    document.getElementById('rain-box').classList.add('close')
    document.getElementById("sign-leaderboard-btn").removeAttribute("disabled")
    document.getElementById("sign-leaderboard-btn").classList.remove("disabled")
    this.precipitation_id = _.random(1, 6);
    console.log(`Precipitation Id used for calculating results: ${this.precipitation_id}`)
    const game_data = this.calculateResult()
    this.experience.gameResults.gameData = game_data
    this.experience.world.backgroundMusic.setVolume(0.05);
    this.experience.world.rainSound.playRainSound(this.precipitation_id)
    setTimeout(() => {
      this.world.rain.createRain(this.precipitation_id)
      this.world.robots.removeRobots()
      this.world.robots.addFinalSceneRobots()
      this.world.stream.updateWaterColorBasedOnScore()
    }, 1000)
    $('#results').delay(6000).fadeIn(1000)
    setTimeout(() => {
      this.experience.world.rainSound.stopRainSound()
      this.experience.world.backgroundMusic.setVolume(0.15);
    }, 8000)
    if ($('#results').css('display') !== 'block' && this.weatherAttempts < 2) {
      document.getElementById("rain-btn").click()
      this.weatherAttempts++
    }
  }

  calculateResult() {
    var sed_max = 7922;
    var sed_span = 7849;
    var phos_max = 12942;
    var phos_span = 12769;
    var nit_max = 65136;
    var nit_span = 64148;
    var mon_min = 2.48;
    var mon_span = 711.36;
    var bio_span = 29;
    var sediment_total = 0.0;
    var nitrogen_total = 0.0;
    var phosphorus_total = 0.0;
    var biodiversity_total = 0.0;
    var money_total = 0.0;
    var precip_id = this.precipitation_id;
    var precip_name = "High";
    var sediment_max = 1000;
    var nitrogen_max = 1000;
    var phosphorus_max = 1000;
    var biodiversity_max = 39;
    var money_max = 1000;
    let count = 0;
    var parcel4_wetland = false;
    var parcel9_wetland = false;
    var parcel4_total = 0.0;
    var parcel9_total = 0.0;

    var alternate_precip_1_name;
    var alternate_precip_1_id;
    var alternate_precip_1_not_severe_text;
    var alternate_precip_1_severe_id;
    var alternate_precip_1_severe_text;

    var alternate_precip_2_name;
    var alternate_precip_2_id;
    var alternate_precip_2_not_severe_text;
    var alternate_precip_2_severe_id;
    var alternate_precip_2_severe_text;

    var alternate_precip_3_id;
    var alternate_precip_3_text;

    var parcels = []

    if (precip_id === 1 || precip_id === 2) {
      precip_name = "Low"
    }
    else if (precip_id === 3 || precip_id === 4) {
      precip_name = "Moderate"
    }
    else if (precip_id === 5 || precip_id === 6) {
      precip_name = "High"
    }

    if (precip_name == "High") {
      alternate_precip_1_name = "Moderate";
      alternate_precip_1_id = 3;
      alternate_precip_1_severe_id = 4
      alternate_precip_1_not_severe_text = "Moderate rainfall without severe weather"
      alternate_precip_1_severe_text = "Moderate rainfall with severe weather"

      alternate_precip_2_name = "Low";
      alternate_precip_2_id = 1;
      alternate_precip_2_severe_id = 2;
      alternate_precip_2_not_severe_text = "Low rainfall without severe weather"
      alternate_precip_2_severe_text = "Low rainfall with severe weather"
      if (precip_id === 5) {
        alternate_precip_3_id = 6;
        alternate_precip_3_text = "High rainfall with severe weather"
      }
      else {
        alternate_precip_3_id = 5;
        alternate_precip_3_text = "High rainfall without severe weather"
      }

    } else if (precip_name == "Moderate") {
      alternate_precip_1_name = "High";
      alternate_precip_1_id = 5;
      alternate_precip_1_severe_id = 6;
      alternate_precip_1_not_severe_text = "High rainfall without severe weather"
      alternate_precip_1_severe_text = "High rainfall with severe weather"

      alternate_precip_2_name = "Low";
      alternate_precip_2_id = 1;
      alternate_precip_2_severe_id = 2;
      alternate_precip_2_not_severe_text = "Low rainfall without severe weather"
      alternate_precip_2_severe_text = "Low rainfall with severe weather"
      if (precip_id === 3) {
        alternate_precip_3_id = 4;
        alternate_precip_3_text = "Moderate rainfall with severe weather"
      }
      else {
        alternate_precip_3_id = 3;
        alternate_precip_3_text = "Moderate rainfall without severe weather"
      }

    } else {
      alternate_precip_1_name = "High";
      alternate_precip_1_id = 5;
      alternate_precip_1_severe_id = 6;
      alternate_precip_1_not_severe_text = "High rainfall without severe weather"
      alternate_precip_1_severe_text = "High rainfall with severe weather"

      alternate_precip_2_name = "Moderate";
      alternate_precip_2_id = 3;
      alternate_precip_2_severe_id = 4;
      alternate_precip_2_not_severe_text = "Moderate rainfall without severe weather"
      alternate_precip_2_severe_text = "Moderate rainfall with severe weather"
      if (precip_id === 1) {
        alternate_precip_3_id = 2;
        alternate_precip_3_text = "Low rainfall with severe weather"
      }
      else {
        alternate_precip_3_id = 1;
        alternate_precip_3_text = "Low rainfall without severe weather"
      }

    }
    var game_data = {
      game_result: {
        water_total: 0,
        sediment_total: 0,
        nitrogen_total: 0,
        phosphorus_total: 0,
        money_total: 0,
        precipitation_id: precip_id,
        biodiversity_score: 0,
        total_score: 0,
        alternate_precipitation_1_name: alternate_precip_1_name,
        alternate_precipitation_1_score: 0,
        alternate_precipitation_2_name: alternate_precip_2_name,
        alternate_precipitation_2_score: 0,
        farm_metric_1_id: null,
        farm_metric_2_id: null,
        farm_metric_3_id: null,
        farm_metric_4_id: null,
        farm_metric_5_id: null,
        farm_metric_6_id: null,
        farm_metric_7_id: null,
        farm_metric_8_id: null,
        farm_metric_9_id: null,
        farm_metric_10_id: null
      }
    };

    var selections = []

    for (var i in this.parcels) {
      var parcel = this.parcels[i]
      count++
      sessionStorage.setItem('parcel' + (i + 1), JSON.stringify(parcel));

      if (parcel.parcel_id == 4) {
        if (parcel.land_use_id == 4) {
          parcel4_wetland = true;
        }
      }

      if (parcel.parcel_id == 9) {
        if (parcel.land_use_id == 4) {
          parcel9_wetland = true;
        }
      }
      parcel.parcel_total_score = 0
      parcel.precipitation_id = precip_id
      selections.push(parcel)
    }


    selections = {
      selections
    }

    parcels = this.parcels
    $.ajax({
      type: "GET",
      url: '/rock_your_watershed/find_metric.json',
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      cache: true,
      data: selections,
      async: false, // to set local variable
      success: function (farm_metrics) {
        for (var i in farm_metrics) {
          console.log(farm_metrics[i].id, farm_metrics[i].parcel_id, farm_metrics[i].sediment)
          var farm_metric = farm_metrics[i]
          if (!farm_metric) return;
          const current_parcel = parcels[`Parcel_Marker_${farm_metric.parcel_id}`]
          sediment_total += parseFloat(farm_metric.sediment);
          nitrogen_total += parseFloat(farm_metric.nitrogen);
          phosphorus_total += parseFloat(farm_metric.phosphorous);
          biodiversity_total += parseFloat(farm_metric.biodiversity);
          money_total += parseFloat(farm_metric.money_spent);
          if (current_parcel.parcel_id === parseInt(Object.keys(game_data.game_result)[current_parcel.parcel_id + 11].toString().charAt(Object.keys(game_data.game_result)[current_parcel.parcel_id + 11].length - 4))
            || (current_parcel.parcel_id === 10 && parseInt(Object.keys(game_data.game_result)[current_parcel.parcel_id + 11].toString().charAt(Object.keys(game_data.game_result)[current_parcel.parcel_id + 11].length - 4)) === 0)) {
            game_data.game_result[Object.keys(game_data.game_result)[current_parcel.parcel_id + 11]] = farm_metric.id
          }

          if (current_parcel.parcel_id === 1 || current_parcel.parcel_id === 2 || current_parcel.parcel_id === 3 || current_parcel.parcel_id === 4) {
            parcel4_total += parseFloat(farm_metric.nitrogen);
          }
          if (current_parcel.parcel_id === 7 || current_parcel.parcel_id === 8 || current_parcel.parcel_id === 9) {
            parcel9_total += parseFloat(farm_metric.nitrogen);
          }

          var sed_score = Math.round(((sed_max - sediment_total) / sed_span) * 1000);
          var pho_score = Math.round(((phos_max - phosphorus_total) / phos_span) * 1000);
          var nit_score = Math.round(((nit_max - nitrogen_total) / nit_span) * 1000);
          var water_score = Math.round(((0.25 * sed_score) + (0.25 * pho_score) + (0.5 * nit_score)));
          var money_score = Math.round(((money_total - mon_min) / mon_span) * 1000);
          var bio_score = Math.round(((biodiversity_max - biodiversity_total) / bio_span) * 1000);

          var total_score = ((0.45 * water_score) + (0.3 * money_score) + (0.25 * bio_score));
          parcels[`Parcel_Marker_${farm_metric.parcel_id}`].parcel_total_score = total_score
        }
      }
    })

    this.parcels = parcels

    var sed_score = Math.round(((sed_max - sediment_total) / sed_span) * 1000);
    var pho_score = Math.round(((phos_max - phosphorus_total) / phos_span) * 1000);

    if (parcel4_wetland == true) {
      nitrogen_total = nitrogen_total - parcel4_total;
      nitrogen_total += (parcel4_total * 0.5);
    }

    if (parcel9_wetland == true) {
      nitrogen_total = nitrogen_total - parcel9_total;
      nitrogen_total += (parcel9_total * 0.5);
    }

    var nit_score = Math.round(((nit_max - nitrogen_total) / nit_span) * 1000);

    var water_score = Math.round(((0.25 * sed_score) + (0.25 * pho_score) + (0.5 * nit_score)));
    var money_score = Math.round(((money_total - mon_min) / mon_span) * 1000);
    var bio_score = Math.round(((biodiversity_max - biodiversity_total) / bio_span) * 1000);

    var total_score = ((0.45 * water_score) + (0.3 * money_score) + (0.25 * bio_score));

    var alternate_precip_score_1 = this.otherPrecipLevelScores(alternate_precip_1_id, alternate_precip_1_name, sediment_max, nitrogen_max, phosphorus_max, biodiversity_max, money_max);
    var alternate_precip_score_1_severe = this.otherPrecipLevelScores(alternate_precip_1_severe_id, alternate_precip_1_name, sediment_max, nitrogen_max, phosphorus_max, biodiversity_max, money_max);
    var alternate_precip_score_2 = this.otherPrecipLevelScores(alternate_precip_2_id, alternate_precip_2_name, sediment_max, nitrogen_max, phosphorus_max, biodiversity_max, money_max);
    var alternate_precip_score_2_severe = this.otherPrecipLevelScores(alternate_precip_2_severe_id, alternate_precip_2_name, sediment_max, nitrogen_max, phosphorus_max, biodiversity_max, money_max);
    var alternate_precip_score_3 = this.otherPrecipLevelScores(alternate_precip_3_id, precip_name, sediment_max, nitrogen_max, phosphorus_max, biodiversity_max, money_max);

    if (money_score < 90) {
      if (precip_name == "Low") {
        total_score = 456;
        alternate_precip_score_1 = 444;
        alternate_precip_score_2 = 450;
      } else if (precip_name == "Moderate") {
        total_score = 450;
        alternate_precip_score_1 = 444;
        alternate_precip_score_2 = 456;
      } else if (precip_name == "High") {
        total_score = 444;
        alternate_precip_score_1 = 450;
        alternate_precip_score_2 = 456;
      }
    }

    total_score = this.scaleFinalScore(total_score);


    total_score = Math.round(total_score);

    game_data.game_result.water_total = water_score
    game_data.game_result.sediment_total = sed_score
    game_data.game_result.nitrogen_total = nit_score
    game_data.game_result.phosphorus_total = pho_score
    game_data.game_result.money_total = money_score
    game_data.game_result.precipitation_id = precip_id
    game_data.game_result.biodiversity_score = bio_score
    game_data.game_result.total_score = total_score
    game_data.game_result.alternate_precipitation_1_name = alternate_precip_1_name
    game_data.game_result.alternate_precipitation_1_score = alternate_precip_score_1
    game_data.game_result.alternate_precipitation_2_name = alternate_precip_2_name
    game_data.game_result.alternate_precipitation_2_score = alternate_precip_score_2
    let best_category = ''
    let second_best_category = ''
    let worst_category = ''
    let scores = [bio_score, water_score, sed_score, nit_score, pho_score]
    scores = _.sortBy(scores)
    let best_score = scores[scores.length - 1]
    let second_best_score = scores[scores.length - 2]
    let worst_score = scores[0]
    if (best_score === bio_score) {
      best_category = 'biodiversity'
    }
    else if (best_score === water_score) {
      best_category = 'water'
    }
    else if (best_score === sed_score) {
      best_category = 'sediment'
    }
    else if (best_score === nit_score) {
      best_category = 'nitrogen'
    }
    else if (best_score === pho_score) {
      best_category = 'phosphorus'
    }
    if (second_best_score === bio_score) {
      second_best_category = 'biodiversity'
    }
    else if (second_best_score === water_score) {
      second_best_category = 'water'
    }
    else if (second_best_score === sed_score) {
      second_best_category = 'sediment'
    }
    else if (second_best_score === nit_score) {
      second_best_category = 'nitrogen'
    }
    else if (second_best_score === pho_score) {
      second_best_category = 'phosphorus'
    }
    if (worst_score === bio_score) {
      worst_category = 'biodiversity'
    }
    else if (worst_score === water_score) {
      worst_category = 'water'
    }
    else if (worst_score === sed_score) {
      worst_category = 'sediment'
    }
    else if (worst_score === nit_score) {
      worst_category = 'nitrogen'
    }
    else if (worst_score === pho_score) {
      worst_category = 'phosphorus'
    }
    let rainfall = ''
    if (precip_name === "Moderate") {
      rainfall = "moderate"
    }
    if (precip_name === "High") {
      rainfall = "high"
    }
    if (precip_name === "Low") {
      rainfall = "low"
    }

    // need to remove applied score color classes in case this is a replay
    const elements = document.querySelectorAll(".high_score1");
    elements.forEach((element => {
      element.classList.forEach((className) => {
        if (className.startsWith("score-color")) {
          element.classList.remove(className)
        }
      })
    }))

    if (nit_score >= 900) {
      $('#nitrogen').toggleClass('score-color-1')
    }
    if (nit_score >= 800 && nit_score <= 899.99) {
      $('#nitrogen').toggleClass('score-color-2')
    }
    if (nit_score >= 700 && nit_score <= 799.99) {
      $('#nitrogen').toggleClass('score-color-3')
    }
    if (nit_score >= 600 && nit_score <= 699.99) {
      $('#nitrogen').toggleClass('score-color-4')
    }
    if (nit_score >= 500 && nit_score <= 599.99) {
      $('#nitrogen').toggleClass('score-color-5')
    }
    if (nit_score >= 400 && nit_score <= 499.99) {
      $('#nitrogen').toggleClass('score-color-6')
    }
    if (nit_score >= 300 && nit_score <= 399.99) {
      $('#nitrogen').toggleClass('score-color-7')
    }
    if (nit_score >= 200 && nit_score <= 299.99) {
      $('#nitrogen').toggleClass('score-color-8')
    }
    if (nit_score >= 100 && nit_score <= 199.99) {
      $('#nitrogen').toggleClass('score-color-9')
    }
    if (nit_score >= 0 && nit_score <= 99.99) {
      $('#nitrogen').toggleClass('score-color-10')
    }
    if (pho_score >= 900) {
      $('#phosphorus').toggleClass('score-color-1')
    }
    if (pho_score >= 800 && pho_score <= 899.99) {
      $('#phosphorus').toggleClass('score-color-2')
    }
    if (pho_score >= 700 && pho_score <= 799.99) {
      $('#phosphorus').toggleClass('score-color-3')
    }
    if (pho_score >= 600 && pho_score <= 699.99) {
      $('#phosphorus').toggleClass('score-color-4')
    }
    if (pho_score >= 500 && pho_score <= 599.99) {
      $('#phosphorus').toggleClass('score-color-5')
    }
    if (pho_score >= 400 && pho_score <= 499.99) {
      $('#phosphorus').toggleClass('score-color-6')
    }
    if (pho_score >= 300 && pho_score <= 399.99) {
      $('#phosphorus').toggleClass('score-color-7')
    }
    if (pho_score >= 200 && pho_score <= 299.99) {
      $('#phosphorus').toggleClass('score-color-8')
    }
    if (pho_score >= 100 && pho_score <= 199.99) {
      $('#phosphorus').toggleClass('score-color-9')
    }
    if (pho_score >= 0 && pho_score <= 99.99) {
      $('#phosphorus').toggleClass('score-color-10')
    }
    if (sed_score >= 900) {
      $('#sediment').toggleClass('score-color-1')
    }
    if (sed_score >= 800 && sed_score <= 899.99) {
      $('#sediment').toggleClass('score-color-2')
    }
    if (sed_score >= 700 && sed_score <= 799.99) {
      $('#sediment').toggleClass('score-color-3')
    }
    if (sed_score >= 600 && sed_score <= 699.99) {
      $('#sediment').toggleClass('score-color-4')
    }
    if (sed_score >= 500 && sed_score <= 599.99) {
      $('#sediment').toggleClass('score-color-5')
    }
    if (sed_score >= 400 && sed_score <= 499.99) {
      $('#sediment').toggleClass('score-color-6')
    }
    if (sed_score >= 300 && sed_score <= 399.99) {
      $('#sediment').toggleClass('score-color-7')
    }
    if (sed_score >= 200 && sed_score <= 299.99) {
      $('#sediment').toggleClass('score-color-8')
    }
    if (sed_score >= 100 && sed_score <= 199.99) {
      $('#sediment').toggleClass('score-color-9')
    }
    if (sed_score >= 0 && sed_score <= 99.99) {
      $('#sediment').toggleClass('score-color-10')
    }
    if (bio_score >= 900) {
      $('#biodiversity').toggleClass('score-color-1')
    }
    if (bio_score >= 800 && bio_score <= 899.99) {
      $('#biodiversity').toggleClass('score-color-2')
    }
    if (bio_score >= 700 && bio_score <= 799.99) {
      $('#biodiversity').toggleClass('score-color-3')
    }
    if (bio_score >= 600 && bio_score <= 699.99) {
      $('#biodiversity').toggleClass('score-color-4')
    }
    if (bio_score >= 500 && bio_score <= 599.99) {
      $('#biodiversity').toggleClass('score-color-5')
    }
    if (bio_score >= 400 && bio_score <= 499.99) {
      $('#biodiversity').toggleClass('score-color-6')
    }
    if (bio_score >= 300 && bio_score <= 399.99) {
      $('#biodiversity').toggleClass('score-color-7')
    }
    if (bio_score >= 200 && bio_score <= 299.99) {
      $('#biodiversity').toggleClass('score-color-8')
    }
    if (bio_score >= 100 && bio_score <= 199.99) {
      $('#biodiversity').toggleClass('score-color-9')
    }
    if (bio_score >= 0 && bio_score <= 99.99) {
      $('#biodiversity').toggleClass('score-color-10')
    }
    if (money_score >= 900) {
      $('#profit').toggleClass('score-color-1')
    }
    if (money_score >= 800 && money_score <= 899.99) {
      $('#profit').toggleClass('score-color-2')
    }
    if (money_score >= 700 && money_score <= 799.99) {
      $('#profit').toggleClass('score-color-3')
    }
    if (money_score >= 600 && money_score <= 699.99) {
      $('#profit').toggleClass('score-color-4')
    }
    if (money_score >= 500 && money_score <= 599.99) {
      $('#profit').toggleClass('score-color-5')
    }
    if (money_score >= 400 && money_score <= 499.99) {
      $('#profit').toggleClass('score-color-6')
    }
    if (money_score >= 300 && money_score <= 399.99) {
      $('#profit').toggleClass('score-color-7')
    }
    if (money_score >= 200 && money_score <= 299.99) {
      $('#profit').toggleClass('score-color-8')
    }
    if (money_score >= 100 && money_score <= 199.99) {
      $('#profit').toggleClass('score-color-9')
    }
    if (money_score >= 0 && money_score <= 99.99) {
      $('#profit').toggleClass('score-color-10')
    }

    document.getElementById("score").innerHTML = JSON.stringify(game_data.game_result.total_score, null, 2)
    document.getElementById("explore-score").innerHTML = JSON.stringify(game_data.game_result.total_score, null, 2)
    document.getElementById("precip").innerHTML = rainfall
    if (precip_id % 2 !== 0) {
      document.getElementById("severity").innerHTML = 'without severe weather'
    }
    else {
      document.getElementById("severity").innerHTML = 'with severe weather'
    }
    //document.getElementById("best-score").innerHTML = best_category
    //document.getElementById("second-best-score").innerHTML = second_best_category
    //document.getElementById("worst-score").innerHTML = worst_category
    document.getElementById("nit-score").innerHTML = nit_score
    document.getElementById("pho-score").innerHTML = pho_score
    document.getElementById("sed-score").innerHTML = sed_score
    document.getElementById("bio-score").innerHTML = bio_score
    document.getElementById("money-score").innerHTML = money_score
    document.getElementById("precip-name").innerHTML = rainfall
    if (precip_id % 2 !== 0) {
      document.getElementById("explore-severity").innerHTML = 'without severe weather'
    }
    else {
      document.getElementById("explore-severity").innerHTML = 'with severe weather'
    }

    document.getElementById("alternate-score-1").innerHTML = alternate_precip_score_2
    document.getElementById("alternate-1").innerHTML = alternate_precip_2_not_severe_text
    document.getElementById("alternate-score-2").innerHTML = alternate_precip_score_2_severe
    document.getElementById("alternate-2").innerHTML = alternate_precip_2_severe_text
    document.getElementById("alternate-score-3").innerHTML = alternate_precip_score_1
    document.getElementById("alternate-3").innerHTML = alternate_precip_1_not_severe_text
    document.getElementById("alternate-score-4").innerHTML = alternate_precip_score_1_severe
    document.getElementById("alternate-4").innerHTML = alternate_precip_1_severe_text
    document.getElementById("alternate-score-5").innerHTML = alternate_precip_score_3
    document.getElementById("alternate-5").innerHTML = alternate_precip_3_text

    $.ajax({
      type: 'POST',
      url: '/rock_your_watershed/save_game_data.json',
      data: game_data,
      success: function (result) {
        document.getElementById("game-result-id").value = result.id
        $.ajax({
          type: 'GET',
          url: '/leaderboards/find_leaderboard_position.json',
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          cache: true,
          data: result,
          async: false, // to set local variable
          success: function (position) {
            document.getElementById("rank").innerHTML = position.result_position
            document.getElementById("leader-count").innerHTML = position.leaderboard_total
            document.getElementById("rank-form").innerHTML = position.result_position
            document.getElementById("leader-count-form").innerHTML = position.leaderboard_total
          }
        });
      }
    });
    this.animalsByBiodiversity(this.scorePercentage(bio_score))
    if (total_score >= 1310) {
      this.world.animals.showBonusAnimal()
    }
    return game_data.game_result
  }


  otherPrecipLevelScores(precip_id, precip_name, sediment_max, nitrogen_max, phosphorus_max, biodiversity_max, money_max) {
    var sed_max = 7922;
    var sed_span = 7849;
    var phos_max = 12942;
    var phos_span = 12769;
    var nit_max = 65136;
    var nit_span = 64148;
    var mon_min = 2.45;
    var mon_span = 711.36;
    var bio_span = 29;
    var sediment_total = 0.0;
    var nitrogen_total = 0.0;
    var phosphorus_total = 0.0;
    var biodiversity_total = 0.0;
    var money_total = 0.0;
    var biodiversity_max = 39;
    var parcel4_wetland = false;
    var parcel9_wetland = false;
    var parcel4_total = 0.0;
    var parcel9_total = 0.0;

    var selections = []


    for (var k in this.parcels) {
      var parcel = this.parcels[k];
      parcel.precipitation_id = precip_id;

      if (parcel.parcel_id == 4) {
        if (parcel.land_use_id == 4) {
          parcel4_wetland = true;
        }
      }

      if (parcel.parcel_id == 9) {
        if (parcel.land_use_id == 4) {
          parcel9_wetland = true;
        }
      }

      selections.push(parcel)
    }

    selections = {
      selections
    }
    $.ajax({
      type: "GET",
      url: '/rock_your_watershed/find_metric.json',
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      cache: true,
      data: selections,
      async: false, // to set local variable
      success: function (farming_metrics) {
        if (!farming_metrics) return;
        for (var i in farming_metrics) {
          var farming_metric = farming_metrics[i]
          sediment_total += parseFloat(farming_metric.sediment);
          nitrogen_total += parseFloat(farming_metric.nitrogen);
          phosphorus_total += parseFloat(farming_metric.phosphorous);
          biodiversity_total += parseFloat(farming_metric.biodiversity);
          money_total += parseFloat(farming_metric.money_spent);
          if (parcel.parcel_id == 1 || parcel.parcel_id == 2 || parcel.parcel_id == 3 || parcel.parcel_id == 4) {
            parcel4_total += parseFloat(farming_metric.nitrogen);
          }
          if (parcel.parcel_id == 7 || parcel.parcel_id == 8 || parcel.parcel_id == 9) {
            parcel9_total += parseFloat(farming_metric.nitrogen);
          }
        }
      }
    });

    var sed_score = Math.round(((sed_max - sediment_total) / sed_span) * 1000);
    var pho_score = Math.round(((phos_max - phosphorus_total) / phos_span) * 1000);

    if (parcel4_wetland == true) {
      nitrogen_total = nitrogen_total - parcel4_total;
      nitrogen_total += (parcel4_total * 0.5);
    }

    if (parcel9_wetland == true) {
      nitrogen_total = nitrogen_total - parcel9_total;
      nitrogen_total += (parcel9_total * 0.5);
    }

    var nit_score_raw = ((nit_max - nitrogen_total) / nit_span) * 1000;
    var nit_score = Math.round(this.clamp(nit_score_raw, 0, 1000));

    var water_score_raw = ((0.25 * sed_score) + (0.25 * pho_score) + (0.5 * nit_score));
    var water_score = Math.round(this.clamp(water_score_raw, 0, 1000));

    var money_score_raw = ((money_total - mon_min) / mon_span) * 1000;
    var money_score = Math.round(this.clamp(money_score_raw, 0, 1000));

    var bio_score_raw = ((biodiversity_max - biodiversity_total) / bio_span) * 1000;
    var bio_score = Math.round(this.clamp(bio_score_raw, 0, 1000));

    var total_score = ((0.45 * water_score) + (0.3 * money_score) + (0.25 * bio_score));

    total_score = this.scaleFinalScore(total_score);
    total_score = Math.round(total_score);
    return total_score;
  }

  clamp(value, min, max) {
    return Math.max(min, Math.min(max, value));
  }

  scaleFinalScore(score) {
    if (score < 500) {
      return (score * 1.467)
    } else if (score >= 500 && score < 600) {
      return (score * 1.03 * 1.467)
    } else if (score >= 600 && score < 700) {
      return (score * 1.06 * 1.467)
    } else if (score >= 700 && score < 800) {
      return (score * 1.09 * 1.467)
    } else if (score >= 800) {
      return (score * 1.125 * 1.467)
    }
  }

  scorePercentage(score) {
    return score / 10
  }

  animalsByBiodiversity(percentage) {
    if (percentage >= 0 && percentage <= 4.99) {
    } else if (percentage >= 5 && percentage <= 14.99) {
      this.world.animals.showAnimals('A1')
    } else if (percentage >= 15 && percentage <= 24.99) {
      this.world.animals.showAnimals('A2')
    } else if (percentage >= 25 && percentage <= 34.99) {
      this.world.animals.showAnimals('A')
    } else if (percentage >= 35 && percentage <= 44.99) {
      this.world.animals.showAnimals('AB1')
    } else if (percentage >= 45 && percentage <= 54.99) {
      this.world.animals.showAnimals('AB2')
    } else if (percentage >= 55 && percentage <= 64.99) {
      this.world.animals.showAnimals('AB3')
    } else if (percentage >= 65 && percentage <= 74.99) {
      this.world.animals.showAnimals('AB')
    } else if (percentage >= 75 && percentage <= 84.99) {
      this.world.animals.showAnimals('ABC1')
    } else if (percentage >= 85 && percentage <= 94.99) {
      this.world.animals.showAnimals('ABC2')
    }
    else if (percentage >= 95) {
      this.world.animals.showAnimals('ABC')
    }
  }
  exploreResults(e) {
    $('#results').fadeOut(1000)
    $('.outer-video-badge-small').fadeOut(1000)
    $('.outer-video-badge').fadeOut(1000)
    $(".results-tab-container").fadeIn(1000)
  }
  signLeaderboard(e) {
    $("#initials-input").val("")
    $("#initials-input").val("")
    $("#city-input").val("")
    $("#st-input").val("")
    $('#leaderboard-form').fadeIn(1000)
  }
  hideLeaderboardForm(e) {
    $('#leaderboard-form').fadeOut(1000)
  }
  submitLeaderboard(e) {
    var leader = {
      leaderboard: {
        initials: $('#initials-input').val().trim(),
        city: $('#city-input').val().trim(),
        state: $('#st-input').val().trim(),
        game_result_id: document.getElementById('game-result-id').value
      }
    }
    if (leader.leaderboard.initials !== "" && leader.leaderboard.city !== "" && leader.leaderboard.state !== "") {
      document.getElementById("sign-leaderboard-btn").setAttribute("disabled", true)
      document.getElementById("sign-leaderboard-btn").classList.add("disabled")
      $.ajax({
        type: 'POST',
        url: '/leaderboards/save_leader.json',
        data: leader,
        success: function () {
          $('#leaderboard-form').fadeOut(1000)
        },
        error: function () {
          document.getElementById("sign-leaderboard-btn").removeAttribute("disabled")
          document.getElementById("sign-leaderboard-btn").classList.remove("disabled")
          alert("We're sorry, we were unable to save your results. Please try again later.")
        }
      });
    }
  }
}