import Experience from "../Experience.js"
import Environment from './Environment.js'
import Landform from './Landform.js'
import Sky from './Sky.js'
import Stream from './Stream.js'
import Rain from './Rain.js'
import Animals from './Animals.js'
import Robots from './Robots.js'
import Dragonfly from './Dragonfly.js'
import BackgroundMusic from "./BackgroundMusic.js"
import RainSound from "./RainSound.js"
import $ from 'jquery'

export default class World {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    this.resources.on('ready', () => {
      this.landform = new Landform()
      this.sky = new Sky()
      this.stream = new Stream()
      this.environment = new Environment()
      this.rain = new Rain()
      this.animals = new Animals()
      this.robots = new Robots()
      this.dragonfly = new Dragonfly()
      this.loadingInterval = this.resources.loadingInterval

      if ($('#game-loading-spinner').is(':visible')) {
        this.remove_loading_spinner();
      }
    })

    this.backgroundMusic = new BackgroundMusic()
    this.rainSound = new RainSound()
  }

  remove_loading_spinner() {
    if (this.loadingInterval) {
      clearInterval(this.loadingInterval);
      this.loadingInterval = null;
    }
    $('#game-loading-spinner').animate({ opacity: 0 }, 1600, function () {
      $(this).css('display', 'none');
    });
    this.backgroundMusic.playMusic();
    this.checkAudioContext();
    $('.navbar-nav .hidden').removeClass('hidden').css('opacity', '0')
    $('.instructions.hidden').removeClass('hidden').css('opacity', '0')
    $('.navbar-nav li').animate({ opacity: 1 }, 1600);
    $('.instructions').css('display', 'block').animate({ opacity: 1 }, 1600);
  }

  checkAudioContext() {
    try {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();

      if (audioContext.state === "suspended") {
        $('.sound-on').click()
      }
    } catch (error) {
      console.error("❌ Error initializing AudioContext:", error);
    }
  }

  update() {
    if (this.sky) {
      this.sky.update()
    }
    if (this.stream) {
      this.stream.update()
    }
    if (this.rain) {
      this.rain.update()
    }
    if (this.animals) {
      this.animals.update()
    }
    if (this.robots) {
      this.robots.update()
    }
    if (this.dragonfly) {
      this.dragonfly.update()
    }
  }
}
