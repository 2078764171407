import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import EventEmitter from "./EventEmitter.js"
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { Modal } from 'bootstrap'
import $ from 'jquery'
export default class Resources extends EventEmitter {
  constructor(sources) {
    super()

    // Options
    this.sources = sources

    // Setup
    this.items = {}
    this.toLoad = this.sources.length
    this.loaded = 0
    //this.showLoadingModal()

    this.loadingText = [
      'Getting the slope of the hills just right...',
      'Talking the duck out of his trailer...',
      'Adding water to fill the stream...',
      'Placing parcel markers...ow! My wheel!',
      'Filling the stream with rainwater...',
      'Helping the animals find hiding spots...',
      'Finding the fertilizer (it likes to run off)...',
    ]

    this.textIndex = 0;

    const url = window.location.href;
    const params = new URLSearchParams(window.location.search);
    const rockYourWatershed = params.get('rock_your_watershed');

    if (params.has('skip_video')) {
      this.load_game_without_video();
    } else {
      this.showLoadingModal();
    }

    this.setLoaders()
    this.startLoading()
  }

  showLoadingModal() {
    THREE.DefaultLoadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
      const gameLoadingModalElement = document.getElementById("game-loading-modal")
      const gameLoadingModal = Modal.getOrCreateInstance(gameLoadingModalElement)
      gameLoadingModal.show()
    };

    THREE.DefaultLoadingManager.onLoad = function () {
      const videoElement = document.getElementById("game-loading-video")
      const showSkipButton = videoElement.getAttribute("videoPlayed") === true || videoElement.getAttribute("new_game") === "true"
    }
  }

  load_game_without_video() {
    $('#game-loading-spinner').css('display', 'block')
    this.updateMessage();
    this.loadingInterval = setInterval(() => this.updateMessage(), 2500);
  }

  updateMessage() {
    const loadingMessage = $('#loading-message');
    const loadingMessageBox = $('#loading-message-box')

    if (!this.updateCount) {
      this.updateCount = 0;
    }

    if (this.updateCount >= 5) {
      clearInterval(this.loadingInterval);
      this.loadingInterval = null;
    }

    loadingMessageBox.animate({ opacity: 0, marginLeft: "-50px" }, 400, () => {
      this.textIndex = (this.textIndex + 1) % this.loadingText.length;
      loadingMessage.text(this.loadingText[this.textIndex]);
      loadingMessageBox.css({ marginLeft: "50px", opacity: 0 });
      loadingMessageBox.animate({ opacity: 1, marginLeft: "0px" }, 400);
      this.updateCount++;
    });
  }

  setLoaders() {
    this.loaders = {}
    this.dracoLoader = new DRACOLoader()
    // https://google.github.io/draco/
    this.dracoLoader.setDecoderPath('loaders/draco/')
    this.loaders.gltfLoader = new GLTFLoader()
    this.loaders.gltfLoader.setDRACOLoader(this.dracoLoader)
    this.loaders.textureLoader = new THREE.TextureLoader()
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
  }

  startLoading() {
    for (const source of this.sources) {
      if (source.type === 'gltfModel') {
        this.loaders.gltfLoader.load(
          source.path,
          (file) => {
            this.sourceLoaded(source, file)
          }
        )
      }
      else if (source.type === 'texture') {
        this.loaders.textureLoader.load(
          source.path,
          (file) => {
            this.sourceLoaded(source, file)
          }
        )
      }
      else if (source.type === 'cubeTexture') {
        this.loaders.cubeTextureLoader.load(
          source.path,
          (file) => {
            this.sourceLoaded(source, file)
          }
        )
      }
    }
  }

  sourceLoaded(source, file) {
    this.items[source.name] = file

    this.loaded++

    if (this.loaded === this.toLoad) {
      this.trigger('ready')
    }
  }
}
